import * as yup from 'yup';
import settings from '@/settings';

export enum AUTH_STEPS {
  'EMAIL',
  'PASSWORD',
  'OTP_VERIFICATION',
  'FORGOT_PASSWORD',
  'RESET_PASSWORD',
}

export const shouldUseCognito = ['reebelo-ca'].includes(settings.store);

export const OTP_LENGTH = 8;
export const PASSWORD_RESET_OTP_LENGTH = 6;

export const PASSWORD_REQUIREMENTS = [
  {
    title: '8+ characters, mix of letters & numbers',
    regex: /^(?=.*[A-Za-z])(?=.*\d).{8,}$/,
  },
  {
    title: 'At least one uppercase & lowercase',
    regex: /^(?=.*?[a-z])(?=.*?[A-Z])/,
  },
];

export const signupFormSchema = yup
  .object()
  .shape({
    firstName: yup.string().required('First name is a required field'),
    lastName: yup.string().required('Last name is a required field'),
    email: yup
      .string()
      .email('Email must be a valid email')
      .required('Email is a required field'),
    password: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
        'Password must be at least 8 characters, include uppercase, lowercase, and a number',
      )
      .required('Password is a required field'),
    acceptsMarketing: yup.bool(),
  })
  .required();
